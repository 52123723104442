<template>
  <div class="watermark__page">
    <v-row no-gutters>
      <v-col cols="12" class="page-header">
        <h1 class="page-title">Watermark</h1>
        <v-divider class="my-5"></v-divider>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <h3>Watermark</h3>
          </v-col>
          <v-col cols="8" class="elevation-form">
            <v-checkbox label="Enable Watermark"></v-checkbox>
            <small>Add watermark to your product images to protect your designs to be copied over the internet.</small>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <h3>Type</h3>
            <p>Choose type for your watermark</p>
          </v-col>
          <v-col cols="8" class="elevation-form">
            <v-radio-group v-model="type" hide-details>
              <v-radio value="text" label="Text"></v-radio>
            </v-radio-group>
            <div class="ml-8">
              <InputDefault label="" suffix="6/20" />
            </div>
            <v-radio-group v-model="type" hide-details>
              <v-radio value="logo" label="Logo (2MB)"></v-radio>
            </v-radio-group>
            <div class="ml-8">
              <small>Supported file: .PNG</small>
              <UploadOneImage @callback="getImageUpLoad" />
            </div>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <h3>Style</h3>
            <p>Choose style for your watermark</p>
          </v-col>
          <v-col cols="8" class="elevation-form">
            <v-checkbox label="Enable Watermark"></v-checkbox>
            <small>Add watermark to your product images to protect your designs to be copied over the internet.</small>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 'text',
    };
  },
  methods: {
    getImageUpLoad(images) {},
  },
};
</script>
